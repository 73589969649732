import { OWCategoryPage } from '../../interfaces/domain/ow-category-page.modal';

export interface OwAppstorePages {
  [key: string]: OWCategoryPage;
}

export const HardCodedPageCategories: OwAppstorePages = {
  appstore: {
    name: 'appstore',
    seoName: 'appstore',
    layoutClassName: 'home-page-layout',
    carousel: {
      name: 'hp',
    },
    metadata: {
      title: 'Overwolf Appstore',
    },
    shelves: [
      {
        name: 'hp_games',
        type: 'banner',
        mobileListView: false,
        layoutStyle: 'expand',
        showViewAll: true,
        viewAllLink: '/browse-by-game/view-all/',
        title: 'Browse Apps by Top Games',
        children: [
          {
            title: 'League of Legends',
            imgUrl: '/static_next/img/games/league-of-legends.webp',
            link: '/browse-by-game/league-of-legends',
          },
          {
            title: 'Valorant',
            imgUrl: '/static_next/img/games/valorant.webp',
            link: '/browse-by-game/valorant',
          },
          {
            title: 'Fortnite',
            imgUrl: '/static_next/img/games/fortnite.webp',
            link: '/browse-by-game/fortnite',
          },
          {
            title: 'Minecraft',
            imgUrl: '/static_next/img/games/minecraft.webp',
            link: '/browse-by-game/minecraft',
          },
          {
            title: 'Counter Strike 2',
            imgUrl: '/static_next/img/games/counter-strike-2.webp',
            link: '/browse-by-game/counter-strike-2',
          },
          {
            title: 'Teamfight Tactics',
            imgUrl: '/static_next/img/games/teamfight-tactics.webp',
            link: '/browse-by-game/teamfight-tactics',
          },
        ],
      },
      {
        name: 'hp_featured_apps',
        type: 'app',
      },
      {
        name: 'hp_new_and_updated',

        type: 'app',
      },
      {
        name: 'hp_updated',
        type: 'app',
      },
      {
        name: 'hp_popular',
        type: 'app',
      },
    ],
  },
  'guides-trainers': {
    name: 'Guides & Trainers',
    pageTitle: 'Guides & Trainers',
    seoName: 'guides-trainers',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'lol_guides_trainers',

        type: 'app',
      },
      {
        name: 'others_guides_trainers',

        type: 'app',
      },
    ],
  },
  'mods-managers': {
    name: 'Mods',
    pageTitle: 'Mods',
    seoName: 'guides-trainers',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'mods_managers',
        type: 'app',
      },
      {
        name: 'server_hosting',
        type: 'app',
      },
    ],
  },
  'enemy-stats': {
    name: 'Enemy Stats',
    pageTitle: 'Enemy Stats',
    seoName: 'enemy-stats',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'enemy_stats',
        type: 'app',
      },
    ],
  },
  'performance-trackers': {
    name: 'Performance Trackers',
    pageTitle: 'Performance Trackers',
    seoName: 'performance-trackers',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'performance_trackers',
        type: 'app',
      },
    ],
  },
  'fun-stuff': {
    name: 'Fun Stuff',
    pageTitle: 'Fun Stuff',
    seoName: 'fun-stuff',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'fun_stuff',
        type: 'app',
      },
    ],
  },
  capture: {
    name: 'Capture & Stream',
    pageTitle: 'Capture & Stream',
    seoName: 'capture',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'capture',
        type: 'app',
      },
      {
        name: 'stream',

        type: 'app',
      },
    ],
  },
  chat: {
    name: 'Communication',
    pageTitle: 'Communication',
    seoName: 'chat',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'communication',

        type: 'app',
      },
    ],
  },
  entertainment: {
    name: 'Music',
    pageTitle: 'Music',
    seoName: 'entertainment',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'music_video',

        type: 'app',
      },
    ],
  },
  utilities: {
    name: 'Utilities',
    pageTitle: 'Utilities',
    seoName: 'utilities',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'utilities',

        type: 'app',
      },
    ],
  },
  'beta-apps': {
    name: 'Beta Apps',
    pageTitle: 'Beta Apps',
    seoName: 'beta-apps',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'beta_apps',
        type: 'app',
      },
    ],
  },
  social: {
    name: 'Social',
    pageTitle: 'Social',
    seoName: 'social',
    layoutClassName: 'category-page-layout',
    shelves: [
      {
        name: 'social_apps',

        type: 'app',
      },
    ],
  },
  'among-us': {
    name: 'Among Us',
    pageTitle: 'Apps for Among Us',
    seoName: 'among-us',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'among_us_popular',

        type: 'app',
      },
    ],
  },
  'league-of-legends': {
    name: 'League of Legends',
    pageTitle: 'Apps for League of Legends',
    seoName: 'league-of-legends',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'lol_popular',

        type: 'app',
      },
      {
        name: 'lol_other',

        type: 'app',
      },
    ],
  },
  valorant: {
    name: 'Valorant',
    pageTitle: 'Apps for Valorant',
    seoName: 'valorant',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'valorant_popular',

        type: 'app',
      },
      {
        name: 'valorant_other',

        type: 'app',
      },
    ],
  },
  fortnite: {
    name: 'Fortnite',
    pageTitle: 'Apps for Fortnite',
    seoName: 'fortnite',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'fortnite_popular',

        type: 'app',
      },
      {
        name: 'fortnite_other',

        type: 'app',
      },
    ],
  },
  minecraft: {
    name: 'Minecraft',
    pageTitle: 'Apps for Minecraft',
    seoName: 'Minecraft',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'minecraft_popular',

        type: 'app',
      },
      {
        name: 'minecraft_other',

        type: 'app',
      },
    ],
  },
  'counter-strike-2': {
    name: 'Counter-Strike 2',
    pageTitle: 'Apps for Counter-Strike 2',
    seoName: 'cs2',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'cs2_popular',

        type: 'app',
      },
      {
        name: 'cs2_other',

        type: 'app',
      },
    ],
  },
  'teamfight-tactics': {
    name: 'Teamfight Tactics',
    pageTitle: 'Apps for Teamfight Tactics',
    seoName: 'teamfight-tactics',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'tft_popular',

        type: 'app',
      },
      {
        name: 'tft_other',

        type: 'app',
      },
    ],
  },
  'rocket-league': {
    name: 'Rocket League',
    pageTitle: 'Apps for Rocket League',
    seoName: 'rocket-league',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'rocket_league_popular',

        type: 'app',
      },
      {
        name: 'rocket_league_other',

        type: 'app',
      },
    ],
  },
  'rainbow-six-siege': {
    name: 'Rainbow Six Siege',
    pageTitle: 'Apps for Rainbow Six Siege',
    seoName: 'rainbow-six-siege',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'rsix_popular',

        type: 'app',
      },
      {
        name: 'rsix_other',

        type: 'app',
      },
    ],
  },
  'world-of-warcraft': {
    name: 'World of Warcraft',
    pageTitle: 'Apps for World of Warcraft',
    seoName: 'world-of-warcraft',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'wow_popular',
        title: 'Popular Apps for Wow',

        type: 'app',
      },
      {
        name: 'wow_other',

        type: 'app',
      },
    ],
  },
  hearthstone: {
    name: 'Hearthstone',
    pageTitle: 'Apps for Hearthstone',
    seoName: 'hearthstone',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'hearthstone_popular',

        type: 'app',
      },
      {
        name: 'hearthstone_other',

        type: 'app',
      },
    ],
  },
  palworld: {
    name: 'Palworld',
    pageTitle: 'Apps for Palworld',
    seoName: 'palworld',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'palworld_popular',
        type: 'app',
      },
    ],
  },
  'diablo-iv': {
    name: 'Diablo IV',
    pageTitle: 'Apps for Diablo IV',
    seoName: 'diablo-iv',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'diablo_iv_popular',
        type: 'app',
      },
    ],
  },
  'apex-legends': {
    name: 'Apex Legends',
    pageTitle: 'Apps for Apex Legends',
    seoName: 'Apex-Legends',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'apex_legends_popular',
        type: 'app',
      },
      {
        name: 'apex_legends_other',
        type: 'app',
      },
    ],
  },
  'new-world': {
    name: 'New World',
    pageTitle: 'Apps for New World',
    seoName: 'new-world',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'new_world_popular',

        type: 'app',
      },
    ],
  },
  starfield: {
    name: 'Starfield',
    pageTitle: 'Apps for Starfield',
    seoName: 'starfield',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'starfield_popular',

        type: 'app',
      },
    ],
  },
  'path-of-exile': {
    name: 'Path of Exile',
    pageTitle: 'Apps for Path of Exile',
    seoName: 'path-of-exile',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'poe_popular',
        type: 'app',
      },
    ],
  },
  warzone: {
    name: 'Call of Duty: Warzone',
    pageTitle: 'Apps for Call of Duty: Warzone',
    seoName: 'path-of-exile',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'warzone_popular',
        title: 'Popular Apps for Warzone',
        type: 'app',
      },
    ],
  },
  'lethal-company': {
    name: 'Lethal Company',
    pageTitle: 'Apps for Lethal Company',
    seoName: 'lethal-company',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'lethal_company_popular',
        type: 'app',
      },
    ],
  },
  'fall-guys': {
    name: 'Fall Guys',
    pageTitle: 'Apps for Fall Guys',
    seoName: 'fall-guys',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'fall_guys_popular',
        type: 'app',
      },
    ],
  },
  'league-of-legends-arena': {
    name: 'League of Legends: Arena',
    pageTitle: 'Apps for League of Legends: Arena',
    seoName: 'league-of-legends-arena',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'lol_arena_Popular',
        title: 'Popular Apps for League Of Legends Arena',
        type: 'app',
      },
    ],
  },
  'sons-of-the-forest': {
    name: 'Sons of the Forest',
    pageTitle: 'Apps for Sons of the Forest',
    seoName: 'sons-of-the-forest',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'sotf_popular',
        type: 'app',
      },
    ],
  },
  'legends-of-runeterra': {
    name: 'Legends of Runeterra',
    pageTitle: 'Apps for Legends of Runeterra',
    seoName: 'Legends-of-Runeterra',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'lor_popular',
        type: 'app',
      },
    ],
  },
  pubg: {
    name: 'PUBG',
    pageTitle: 'Apps for PUBG',
    seoName: 'pubg',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'pubg_popular',
        type: 'app',
      },
      {
        name: 'pubg_other',
        type: 'app',
      },
    ],
  },
  'magic-the-gathering-arena': {
    name: 'Magic: The Gathering Arena',
    pageTitle: 'Apps for Magic: The Gathering Arena',
    seoName: 'magic-the-gathering-arena',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'mtga_popular',
        type: 'app',
      },
    ],
  },
  starcraft2: {
    name: 'StarCraft II',
    pageTitle: 'Apps for StarCraft II',
    seoName: 'starcraft2',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'starcraft_two_popular',
        type: 'app',
      },
      {
        name: 'starcraft_two_other',
        type: 'app',
      },
    ],
  },
  splitgate: {
    name: 'Splitgate',
    pageTitle: 'Apps for Splitgate',
    seoName: 'splitgate',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'splitgate_popular',
        type: 'app',
      },
      {
        name: 'splitgate_other',
        type: 'app',
      },
    ],
  },
  dota2: {
    name: 'Dota 2',
    pageTitle: 'Apps for Dota 2',
    seoName: 'dota2',
    layoutClassName: 'game-page-layout',
    shelves: [
      {
        name: 'dota_two_popular',
        type: 'app',
      },
      {
        name: 'dota_two_other',
        type: 'app',
      },
    ],
  },
  'view-all': {
    name: 'View-all',
    pageTitle: 'Browse Apps by Game',
    seoName: 'view-all',
    layoutClassName: 'all-games-layout',
    shelves: [
      {
        name: 'view-all-games',
        showViewAll: false,
        mobileLimit: 10,
        type: 'banner',
        children: [
          {
            title: 'League of Legends',
            imgUrl: '/static_next/img/games/league-of-legends.webp',
            link: '/browse-by-game/league-of-legends/',
          },
          {
            title: 'VALORANT',
            imgUrl: '/static_next/img/games/valorant.webp',
            link: '/browse-by-game/valorant',
          },
          {
            title: 'Fortnite',
            imgUrl: '/static_next/img/games/fortnite.webp',
            link: '/browse-by-game/fortnite',
          },
          {
            title: 'Minecraft',
            imgUrl: '/static_next/img/games/minecraft.webp',
            link: '/browse-by-game/minecraft',
          },
          {
            title: 'Counter-Strike 2',
            imgUrl: '/static_next/img/games/counter-strike-2.webp',
            link: '/browse-by-game/counter-strike-2/',
          },
          {
            title: 'Teamfight Tactics',
            imgUrl: '/static_next/img/games/teamfight-tactics.webp',
            link: '/browse-by-game/teamfight-tactics/',
          },
          {
            title: 'Rocket League',
            imgUrl: '/static_next/img/games/rocket-league.webp',
            link: '/browse-by-game/rocket-league',
          },
          {
            title: 'Hearthstone',
            imgUrl: '/static_next/img/games/hearthstone.webp',
            link: '/browse-by-game/hearthstone',
          },
          {
            title: 'Rainbow Six Siege',
            imgUrl: '/static_next/img/games/rainbow-six-game-series.webp',
            link: '/browse-by-game/rainbow-six-siege',
          },
          {
            title: 'World of Warcraft',
            imgUrl: '/static_next/img/games/world-of-warcraft.webp',
            link: '/browse-by-game/world-of-warcraft',
          },
          {
            title: 'Palworld',
            imgUrl: '/static_next/img/games/palworld.webp',
            link: '/browse-by-game/palworld/',
          },
          {
            title: 'Apex Legends',
            imgUrl: '/static_next/img/games/apex-legends.webp',
            link: '/browse-by-game/apex-legends/',
          },
          {
            title: 'Diablo IV',
            imgUrl: '/static_next/img/games/diablo-4.webp',
            link: '/browse-by-game/diablo-iv/',
          },
          // {
          //   title: 'ROBLOX',
          //   imgUrl: '/static_next/img/games/roblox.webp',
          //   link: '/browse-by-game/roblox/',
          // },
          {
            title: 'DOTA2',
            imgUrl: '/static_next/img/games/dota-2.webp',
            link: '/browse-by-game/dota2',
          },
          {
            title: 'Lethal Company',
            imgUrl: '/static_next/img/games/lethal-company.webp',
            link: '/browse-by-game/lethal-company',
          },
          {
            title: 'warzone',
            imgUrl: '/static_next/img/games/call-of-duty-warzone.webp',
            link: '/browse-by-game/warzone',
          },
          {
            title: 'Magic: The Gathering Arena',
            imgUrl: '/static_next/img/games/magic-the-gathering-arena.webp',
            link: '/browse-by-game/magic-the-gathering-arena',
          },
          {
            title: 'PUBG',
            imgUrl: '/static_next/img/games/pubg.webp',
            link: '/browse-by-game/pubg',
          },
          // {
          //   title: 'Overwatch 2',
          //   imgUrl: '/static_next/img/games/overwatch-2.webp',
          //   link: '/browse-by-game/overwatch',
          // },
          // {
          //   title: 'The Sims 4',
          //   imgUrl: '/static_next/img/games/the-sims-4.webp',
          //   link: '/browse-by-game/the-sims-4/',
          // },
          // {
          //   title: 'Baldurs Gate 3',
          //   imgUrl: '/static_next/img/games/baldurs-gate-3.webp',
          //   link: '/browse-by-game/baldurs-gate-3/',
          // },
          // {
          //   title: 'Warframe',
          //   imgUrl: '/static_next/img/games/warframe.webp',
          //   link: '/browse-by-game/warframe/',
          // },
          {
            title: 'Path of Exile',
            imgUrl: '/static_next/img/games/path-of-exile.webp',
            link: '/browse-by-game/path-of-exile',
          },
          {
            title: 'Among Us',
            imgUrl: '/static_next/img/games/among-us.webp',
            link: '/browse-by-game/among-us',
          },
          // {
          //   title: 'The Finals',
          //   imgUrl: '/static_next/img/games/the-finals.webp',
          //   link: '/browse-by-game/the-finals/',
          // },
          {
            title: 'Sons of the Forest',
            imgUrl: '/static_next/img/games/sons-of-the-forest.webp',
            link: '/browse-by-game/sons-of-the-forest',
          },
          {
            title: 'Fall Guys',
            imgUrl: '/static_next/img/games/fall-guys.webp',
            link: '/browse-by-game/fall-guys',
          },
          {
            title: 'New World',
            imgUrl: '/static_next/img/games/new-world.webp',
            link: '/browse-by-game/new-world',
          },
          {
            title: 'Legends of Runeterra',
            imgUrl: '/static_next/img/games/legends-of-runeterra.webp',
            link: '/browse-by-game/legends-of-runeterra',
          },
          {
            title: 'StarCraft II',
            imgUrl: '/static_next/img/games/starcraft-2.webp',
            link: '/browse-by-game/starcraft2',
          },
          {
            title: 'Splitgate',
            imgUrl: '/static_next/img/games/splitgate.webp',
            link: '/browse-by-game/splitgate',
          },
          {
            title: 'Starfield',
            imgUrl: '/static_next/img/games/starfield.webp',
            link: '/browse-by-game/starfield',
          },
          {
            title: 'League of Legends: Arena',
            imgUrl: '/static_next/img/games/league-of-legends-arena.webp',
            link: '/browse-by-game/league-of-legends-arena',
          },
        ],
        Sort: {
          sortDirection: 'asc',
          sortField: 'title',
        },
      },
    ],
  },
};
